import { Controller } from '@hotwired/stimulus'
import { Dropdown } from 'flowbite'
import { stimulus } from '~/init'

export default class DropdownController extends Controller {
  static targets = ['trigger', 'menu', 'autofocus']

  connect () {
    if (this.hasMenuTarget && this.hasTriggerTarget) {
      this.dropdown = new Dropdown(this.menuTarget, this.triggerTarget, this.#options())
    }
  }

  disconnect () {
    this.hide()
  }

  show () {
    this.dropdown?.show()
  }

  hide () {
    this.dropdown?.hide()
  }

  toggle () {
    this.dropdown?.toggle()
  }

  #options () {
    const {
      placement = this.data.get('placement') || 'bottom',
      triggerType = 'click',
      offsetSkidding = '0',
      offsetDistance = '5',
      delay = '300',
      ignoreClickOutsideClass = false
    } = this.element.dataset

    return {
      placement,
      triggerType,
      offsetSkidding: parseInt(offsetSkidding),
      offsetDistance: parseInt(offsetDistance),
      delay: parseInt(delay),
      ignoreClickOutsideClass,
      onShow: () => {
        this.hasAutofocusTarget && this.autofocusTarget.focus()
        this.dispatch('show')
      },
      onHide: () => {
        this.dispatch('hide')
      }
    }
  }
}

stimulus.register('dropdown', DropdownController)
