import { Controller } from '@hotwired/stimulus'
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import { stimulus } from '~/init'

const unusualTextAlignTypes = ['center', 'right', 'justify']

export default class Draftjs extends Controller {
  connect () {
    this.element.innerHTML = this.#convertToHTML()
  }

  #convertToHTML () {
    const rawContent = this.element.dataset.rawContent
    if (rawContent) {
      const rawContentState = convertFromRaw(JSON.parse(rawContent))
      return stateToHTML(rawContentState, this.#getOptions())
    }
  }

  #getOptions () {
    return {
      blockStyleFn: (block) => {
        const blockType = block?.getType()
        if (unusualTextAlignTypes.includes(blockType)) {
          return { style: { textAlign: blockType } }
        }
      },
      inlineStyles: {
        BOLD: { element: 'span', attributes: { class: 'font-bold' } },
        SUP: {
          element: 'sup',
          attributes: { class: 'align-super text-[10px]' }
        },
        LINK: { element: 'a', attributes: { target: '_blank' } }
      },
      inlineStyleFn: (styles) => {
        const key = 'CUSTOM_COLOR_'
        const color = styles.find((value) => value.startsWith(key))
        if (color) {
          return {
            element: 'span',
            style: { color: color.replace(key, '') }
          }
        }
      },
      entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase()
        if (entityType === 'link') {
          const data = entity.getData()
          const isExternal = new URL(data.url, window.location.origin).origin !== window.location.origin
          return {
            element: 'a',
            attributes: {
              href: data.url,
              'data-external-link': isExternal ? 'true' : 'false',
              title: `Link: ${data.url}`,
              target: isExternal ? '_blank' : '_self',
              rel: isExternal ? 'noopener noreferrer' : ''
            }
          }
        }
      }
    }
  }
}

stimulus.register('draftjs', Draftjs)
